@import 'globalStyles/variables';
@import 'globalStyles/typographyMixins';

.item {
  padding: 0 $offset4;
  height: 40px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  .item-value {
    width: 182px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: $grey50;
  }

  .counter {
    text-align: right;
  }
}
