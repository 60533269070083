@import 'globalStyles/variables';
@import 'globalStyles/typographyMixins';

.dropdown {
  position: absolute;
  padding: 0 $offset10;
  left: 0px;
  background-color: $whiteMaster;
  border-radius: $borderRadius8;
  box-shadow: $shadowSoft;
  z-index: 10;

  .calendar-caption {
    @include Subtitle1();
  }

  .calendar-nav,
  .calendar-nav-right {
    position: absolute;
    top: 130px;
    left: 10px;
    color: $grey400;
  }

  .calendar-nav-right {
    left: 370px;
  }

  .calendar-row {
    margin-top: $offset4;
  }

  .calendar-cell {
    width: 40px;
    height: 40px;
    padding: $offset1 $offset1 0 0;
  }

  .calendar-button {
    cursor: pointer;
    &:hover:not(.selected):not(.selected-start):not(.selected-end):not(.disabled):not(.custom-from-date) {
      background-color: $blue50;
      border-radius: $borderRadius4;
    }
  }

  .selected-start,
  .selected-end {
    width: 100%;
    color: $whiteMaster;
    background-color: $blue700;
    margin-right: 6px;
    border-radius: $borderRadius4;
  }

  .selected,
  .custom-from-date {
    color: $whiteMaster;
    background-color: $blue700;
    border-radius: $borderRadius4;
  }

  .today:not(.selected):not(.selected-start):not(.selected-end):not(.custom-from-date) {
    background-color: whiteMaster;
    border-radius: $borderRadius4;
    border: 1px solid $blue700;
    color: $blue700;
  }

  .range-selected {
    color: $grey900;
    background: $blue50;
    border-radius: 0;
  }

  .disabled {
    opacity: 0.4;

    &:hover {
      cursor: not-allowed;
    }
  }
}
