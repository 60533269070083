@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.autocomplete-wrapper {
  width: 240px;
}

.refresh-icon {
  width: 100%;
  display: flex;
  align-items: center;
}

.list {
  @include listWrapper();

  .header {
    height: 57px;
    display: flex;
    justify-content: flex-end;
    gap: $offset4;
    padding: $offset3 $offset4;
    border-bottom: 1px solid $blue20;
  }

  .table {
    width: 100%;

    .company-id {
      display: flex;
      align-items: center;
      gap: $offset2;
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
