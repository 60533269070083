@import 'globalStyles/mixins';

.title {
  display: flex;
  align-items: center;

  .tooltip-wrapper {
    margin-left: $offset2;

    .popup-content {
      width: 268px;
      height: 62px;
    }
  }
}

.company-badge {
  margin-left: $offset3;
  background-color: $whiteMaster;
  padding: 0 $offset2;
  border-radius: $borderRadius2;
}

.right-side {
  display: flex;
  align-items: center;
  gap: $offset6;
  margin-bottom: $offset2;
}

.autocomplete-wrapper {
  width: 240px;
}

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $offset3 $offset4;
    height: 100%;
    border-bottom: 1px solid $blue20;
    gap: $offset4;

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: $offset4;
    }

    .button {
      margin-right: $offset3;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue10;
      border-radius: $borderRadius4;
      width: 132;
    }

    .download-link {
      border-left: 1px solid $blue20;
      padding-left: $offset4;
    }
  }

  .table {
    width: 100%;

    .toggle {
      display: flex;
      justify-content: center;
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
