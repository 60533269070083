@import 'globalStyles/variables';

.radio-button-label {
  margin-bottom: $offset4;
}

.radio-buttons {
  display: flex;
  gap: $offset6;
}

.row-with-actions {
  display: flex;
  gap: $offset6;
}

.duration {
  width: 100%;
}

.action {
  min-width: 48px;
  margin-top: 25px; // * label height
}

.distance-wrapper {
  width: 226px;
}
