@import 'globalStyles/variables';

.head {
  border-right: 1px solid $grey100;
  padding: $offset2 $offset4;
  position: relative;
  height: 52px;
  background-color: $blue10;
  text-align: left;
  user-select: none;

  .item-left-border {
    background: transparent;
    height: 100%;
    width: 4px;
    position: absolute;
    right: -$offset1;
    z-index: 2;
    cursor: col-resize;
  }

  &:last-child {
    border-right: none;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    .filter {
      margin-left: $offset2;
    }
  }
}
