@import 'globalStyles/mixins';

.list {
  @include listWrapper();

  .trash-icon {
    width: 100%;
    text-align: center;
  }

  .header {
    padding: $offset3 $offset4;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $blue20;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: $offset4;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue10;
      border-radius: $borderRadius4;
      height: 32px;
      width: 148px;
    }
  }

  .table {
    width: 100%;
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
