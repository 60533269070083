@import 'globalStyles/variables';

.table-row {
  border-bottom: 1px solid $grey100;
  border-top: 1px solid $grey100;

  &:hover {
    background-color: $blue10;
    cursor: pointer;

    .remove-icon-block {
      position: absolute;
      display: inline-block;
      right: $offset3;
      top: calc(50% - 10px);
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .item {
    max-width: $offset4;
    padding: $offset2 $offset4;

    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;

      &.row-wrap {
        white-space: wrap;
      }
    }
  }

  &.active {
    background-color: $blue10;

    td {
      border-top: 1px solid $blue700;
      border-bottom: 1px solid $blue700;
    }

    td:not(.row-with-icon) {
      padding: $offset2 $offset4;
    }
  }

  .row-with-icon {
    padding: $offset2 $offset12 $offset2 $offset4;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .remove-icon {
    width: 20px;
    height: 20px;
  }
}

.remove-icon-block {
  display: none;
}
