@import 'globalStyles/mixins';

.rates-modal {
  width: 1248px;
  height: 100%;
  padding: $offset6;
}

.list {
  @include listWrapper();

  .trash-icon {
    width: 100%;
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $offset3;
    border-bottom: 1px solid $blue20;

    .buttons {
      display: flex;

      .filter-icon {
        padding-left: $offset4;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .car-class-wrapper {
        border-left: 1px solid $blue20;
        border-right: 1px solid $blue20;
        padding-left: $offset4;
      }
    }
  }

  .table {
    width: 100%;
  }
}

.pagination {
  margin-top: $offset6;
  margin-bottom: $offset12;
}

.rate-info {
  border-bottom: 1px solid $blue20;
  margin-bottom: $offset6;
  padding-bottom: $offset6;
  display: flex;
  gap: $offset6;
  width: 100%;

  .wrapper {
    width: 270px;

    .location-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: $offset2;

      .location {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;

  .right-side {
    display: flex;
    gap: $offset4;
  }
  .action-button {
    width: 200px;
  }
}

.single-range {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: $offset8;
}

.single-range-block-title {
  padding-bottom: $offset1;
}

.single-range-block-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $offset4;
  width: 100%;
  height: 48px;
  border: 1px solid $blue20;
  border-radius: $borderRadius4;
}

.range-wrapper {
  width: 267px;
}
