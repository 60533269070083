@import 'globalStyles/variables';
@import 'globalStyles/typographyMixins';
@import 'globalStyles/mixins';

.filter-icon-wrapper {
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: $offset2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .filter-active {
    & > path {
      fill: $blue700;
    }
  }

  .selected {
    position: absolute;
    right: 0;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: $borderRadius3;
    background-color: $red600Master;
  }
}

.disabled {
  cursor: not-allowed;
}

.dropdown-wrapper {
  position: fixed;
  z-index: 10;
}

.filter-modal {
  width: 586px;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  overflow: hidden;
}

.filter-modal-header {
  height: 52px;
  background: $blue10;
  display: flex;
  align-items: center;
  padding: $offset2 $offset4;
  border-bottom: 1px solid $blue20;
}

.header-first-title {
  width: 254px;
  margin-right: $offset4;
}

.header-second-title {
  margin-left: $offset4;
}

.filter-body {
  background: $whiteMaster;
  display: flex;
  height: 316px;
}
.filter-left-side {
  width: 292px;
  border-right: 1px solid $blue20;
}
.filter-right-side {
  width: 292px;
}

.filter-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $whiteMaster;
  padding: $offset2;
  border-top: 1px solid $blue20;
}

.filter-apply {
  width: 124px;
}

.reset-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reset-icon {
  margin-right: $offset2;
  display: flex;
  align-items: center;
}
