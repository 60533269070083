@import 'globalStyles/variables';

.subtitle {
  margin-top: $offset6;
}

.insurance {
  display: flex;
  align-items: center;
  gap: $offset2;
}

.insurance-tooltip {
  width: 222px;
}

.road-assistance {
  border-bottom: 1px solid $blue20;
  padding-bottom: $offset6;
}

.default-description {
  margin-top: $offset2;
  margin-bottom: $offset6;
}

.info {
  margin-top: $offset4;
  margin-bottom: $offset6;
  background: $blue10;
  border-radius: $borderRadius4;
  padding: $offset3 $offset4;
  display: flex;
  gap: $offset3;
}
